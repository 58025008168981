import React, {useState} from 'react';
import {graphql} from 'gatsby';
import loadable from '@loadable/component'
import Layout from '../components/layout/index.amp';
import {getArticleLinks} from "../utils/articleUtil";
import CategoryTitleBordered from "../components/helpers/categoryTitleBordered.amp";
import {map as _map} from "lodash";

const ArticlePreview = loadable(() => import("./articlePreview.amp"));

const Homepage = props => {
    const displayedArticles = [];

    const getHeroArticle = () => {
        let featuredArticle = (props.data.featured.nodes || [])
            .find(article => new Date(article['featuredTill']) >= new Date()) || props.data.editorsChoice.nodes[0];
        if (featuredArticle) {
            let category = featuredArticle.category.find(item => !!item.parent)
            featuredArticle.categoryTitle = category?.parent?.title;
            displayedArticles.push(featuredArticle.id);
        }
        return featuredArticle;
    };

    const [heroArticle, setHeroArticle] = useState(getHeroArticle());

    const excludeAlreadyDisplayed = (articles) => {
        return articles.filter(article => !displayedArticles.includes(article.id));
    }
    return (
        <Layout>
            <main className={"wrapper"} data-datocms-noindex>
                <div className={"mb-4"}>
                    <a className={"flex-1"} href={getArticleLinks(heroArticle).getReadMore()}>
                        <div className={"cursor-pointer relative"}>
                            <amp-img
                                src={heroArticle.cover.url}
                                width={heroArticle.cover.width} height={heroArticle.cover.height} layout="responsive" alt={heroArticle.heading}/>
                            <div className={"px-8 text-sm font-bold bg-gray-100 absolute"} style={{left: 0, bottom: 0}}>{heroArticle.categoryTitle}</div>
                        </div>
                        <div
                            className={"text-black hero-article-title text-2xl lg:text-2xl mt-3 font-bold leading-snug font-sans text-center lg:text-left"}>
                            {heroArticle.heading}
                        </div>
                    </a>
                </div>
                <div className={"grid grid-cols-2 gap-10"}>
                    {_map(props.pageContext.indexPageCategoryWiseArticles, (data, key) => {
                        return <div className={"col-span-2"}>
                            <CategoryTitleBordered title={data.title} link={`/${key}`} className={"mb-4 lg:mb-6"}/>
                            <div className={"flex px-2 lg:px-0"}>
                                {
                                    excludeAlreadyDisplayed(data.articles).slice(0, 2).map((article, index) => {
                                        displayedArticles.push(article.id);
                                        return <ArticlePreview
                                            key={`news-${index}`} data={article}
                                            className={`flex-1 first:ml-0 last:mr-0 mx-2 ${index === 2 ? 'hidden md:block' : ''}`}
                                            headingClass={"text-xl font-arial font-semibold leading-snug"}
                                            noBody={true}
                                            noFlag={true}
                                            noAuthor={true}
                                            dateAsFlag={true}
                                            publishedAtFormat={"mmmm dd, yyyy"}/>
                                    })
                                }
                            </div>
                        </div>
                    })}
                </div>
            </main>
        </Layout>
    )
}

export const query = graphql`
    query indexPage($top: [String], $currentDateTime: Date) {
        featured: allDatoCmsArticle(filter: {featuredTill: {gte: $currentDateTime}, website: {elemMatch: {name: {eq: "pt"}}}}
            sort: {order: DESC, fields: publishedAt}
        ) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
        editorsChoice: allDatoCmsArticle(filter: {category: {elemMatch: {originalId: {in: $top}}}, website: {elemMatch: {name: {eq: "pt"}}}}
            sort: {order: DESC, fields: publishedAt}
            limit: 1
        ) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
    }
`

export default Homepage
